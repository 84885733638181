export const PRIMARY = '#FECF28';
export const SECONDARY = '#161616';

export const FONT_COLOR = '#161616';
export const BACKGROUND_COLOR = '#FFFFFF';

export const SECONDARY_GREEN = '#00A344';
export const SECONDARY_RED = '#E63B35';
export const SECONDARY_ORANGE = '#F88F0D';
export const SECONDARY_PURPLE = '#982AD8';
export const SECONDARY_BLUE = '#0088FF';

export const BADGE_COLOR = '#FECF28';

export const GRAY_PRIMARY = '#DEDEDE';
export const GRAY_SECONDARY = '#B4B4B4';
export const GRAY_THIRD = '#6E6E6E';
export const GRAY_LIGHT = '#F8F8F8';
export const WHITE = '#FFFFFF';
export const WHITE_IMPORTANT = '#FFFFFF !important';

export const ERROR_COLOR = '#E63B35';
export const WARNING_COLOR = '#F88F0D';
export const SUCCESS_COLOR = '#00A344';

export const RED = '#E63B35';
export const ORANGE = '#F88F0D';
export const GREEN = '#00A344';
export const BLACK = '#161616';

export const YELLOW100 = '#CBA520';
export const YELLOW90 = '#FECF28';
export const YELLOW80 = '#FED853';
export const YELLOW70 = '#FEE27E';
export const YELLOW60 = '#FEEBA9';
export const YELLOW50 = '#FFF5D4';

export const BLUE100 = '#006CCC';
export const BLUE90 = '#0088FF';
export const BLUE80 = '#339FFF';
export const BLUE70 = '#66B7FF';
export const BLUE60 = '#99CFFF';
export const BLUE50 = '#CCE7FF';

export const GREEN100 = '#008236';
export const GREEN90 = '#00A344';
export const GREEN80 = '#33B569';
export const GREEN70 = '#66C78E';
export const GREEN60 = '#99DAB4';
export const GREEN50 = '#CCECD9';

export const RED100 = '#C93310';
export const RED90 = '#E63B35';
export const RED80 = '#EB625D';
export const RED70 = '#F08985';
export const RED60 = '#F5B0AE';
export const RED50 = '#FAD7D6';

export const ORANGE100 = '#C6720A';
export const ORANGE90 = '#F88F0D';
export const ORANGE80 = '#F9A53D';
export const ORANGE70 = '#FABB6D';
export const ORANGE60 = '#FCD29E';
export const ORANGE50 = '#FDE8CE';

export const PURPLE100 = '#7921AC';
export const PURPLE90 = '#982AD8';
export const PURPLE80 = '#AC54DF';
export const PURPLE70 = '#C17FE7';
export const PURPLE60 = '#D5A9EF';
export const PURPLE50 = '#EAD4F7';

export const BLACK90 = '#161616';
export const BLACK70 = '#666666';
export const BLACK50 = '#B3B3B3';
export const BLACK30 = '#E6E6E6';
export const BLACK10 = '#F9F9F9';
